import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import { FaArrowsAltV } from "react-icons/fa";
import { PaginationSkeleton } from "../../assets/PaginationSkeleton";
import { RefressButton } from "../../assets/buttons/RefressButton";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getAllContact } from "../../redux/actions/ContactAction";
import { useNavigate } from "react-router-dom";
import { TableBodyDataSkeleton } from "../../assets/TableBodyDataSkeleton";
import { allStyles } from "../../allStyles";
import { NoDataSetMessage } from "../../components/NoDataSetMessage";
import { FcAlphabeticalSortingAz } from "react-icons/fc";
import {AiOutlineMessage }from 'react-icons/ai'

const columns = [
  {
    id: "serialNumber",
    label: "S#",
    minWidth: 20,
    align: "center",
    sortAction: false,
  },
  {
    id: "name",
    label: "name",
    minWidth: 60,
    align: "center",
    sortAction: true,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 200,

    align: "center",
    sortAction: true,
  },
  {
    id: "purpose",
    label: "purpose",
    minWidth: 60,
    align: "center",
    sortAction: true,
  },
  {
    id: "created_at",
    label: "Date",
    minWidth: 200,
    align: "center",
    sortAction: true,
  },
  {
    id: "created_at",
    label: "Time",
    minWidth: 200,
    align: "center",
    sortAction: true,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 200,
    align: "center",
    sortAction: true,
  },
];

export const Contact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState("ASC");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [contactData,setContactData]=useState('')
  const tagsState = useSelector((state) => state.tagsReducer);
  console.log(tagsState)
  const DateConverter = (date) => {
    const newDate = new Date(date);
    return `${newDate.getDate()}-${
      newDate.getMonth() + 1
    }-${newDate.getFullYear()}`;
  };
  const timeConverter = (date) => {
    const newDate = new Date(date);
    return `${
      newDate.getHours() > 12
        ? `${newDate.getHours() - 12}`
        : `${newDate.getHours()}`
    }:${newDate.getMinutes()}  ${newDate.getHours() > 12 ? `PM` : "AM"}  `;
  };
  const sortingColumns = (col) => {
    if (orderData === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setRows([...sorted]);
      setOrderData("DSC");
    } else if (orderData === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setRows([...sorted]);
      setOrderData("ASC");
    } else {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setRows([...sorted]);
      setOrderData("ASC");
    }
  };

  useEffect(() => {
    setLoading(tagsState.isLoading);
    setRows(tagsState.tags);
  }, []);

  useEffect(() => {
    setLoading(tagsState.isLoading);
    setRows(tagsState.tags);
  }, [tagsState]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
        }}   >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Contact Query Message
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {contactData}
          </Typography>
        </Box>
      </Modal>
      <Stack
        sx={{ px: 1, mb: 2 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h5">Contact</Typography>
        </Stack>

        {/* <Stack spacing={3} direction="row" alignItems="center">
          <RefressButton disabled={loading} onClickHandle={refreshHandle} />
        </Stack> */}
      </Stack>

      <Stack>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={allStyles.tableHeight}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.sortAction ? (
                        <Button
                          sx={allStyles.actionsButtons}
                          onClick={() => sortingColumns(column.id)}
                        >
                          {column.label}
                          <FcAlphabeticalSortingAz
                            size={20}
                            style={{ marginLeft: "15px" }}
                          />
                        </Button>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loading ? (
                <TableBodyDataSkeleton
                  numberOfColumns={columns.length}
                  imagePreview={false}
                  editAction={true}
                  deleteAction={false}
                />
              ) : rows.length !== 0 ? (
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow hover tabIndex={-1} key={i}>
                          {columns.map((column, ind) => {
                            const value = row[column.id];
                            return column.id === "serialNumber" ? (
                              <TableCell key={ind} align={column.align}>
                                {page < 1
                                  ? i + 1
                                  : page * rowsPerPage + (i + 1)}
                              </TableCell>
                            ) : column.id === "industry" ? (
                              <TableCell key={ind} align={column.align}>
                                {value.title}
                              </TableCell>
                            ) : column.id==='action'? (
                              <TableCell key={ind} align={column.align}>
                              <Stack
                                direction='row'
                                spacing={2}
                                justifyContent='center'
                              >
                                <IconButton
                                  onClick={() => {
                                    navigate(`/contact/${row.id}`);
                                    handleOpen()
                                  }}
                                  aria-label='edit'
                                >
                                  <AiOutlineMessage color="#2f6deb" />
                                </IconButton>
                              </Stack>
                            </TableCell>
                            )  :(
                              <TableCell key={ind} align={column.align}>
                                {column.id === "created_at"
                                  ? column.label === "Time"
                                    ? timeConverter(value)
                                    : DateConverter(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <NoDataSetMessage lengthOfColumns={columns.length} />
              )}
            </Table>
          </TableContainer>

          {loading ? (
            <PaginationSkeleton />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Stack>
      <ToastContainer />
    </Box>
  );
};
