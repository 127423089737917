import { combineReducers } from "redux";
import userStateReducer from "./userDataReducers/userState";
import userTokensReducer from "./userDataReducers/userTokens";
import categoriesReducer from "./dataReducers/categoriesData";
import eventsReducer from "./dataReducers/eventsData";
import tagsReducer from "../reducers/dataReducers/tagsData";
import jobOpportunitiesReducer from "../reducers/dataReducers/jobOpportunitiesData";
import settingReducer from "../reducers/dataReducers/SettingData";
import BookingReducer from "./dataReducers/BookingData";
import Promocode from "./dataReducers/promoCode_reducer";
import FaqReducer from "./dataReducers/FaqData";

const reducers = combineReducers({
  userStateReducer,
  userTokensReducer,
  eventsReducer,
  categoriesReducer,
  tagsReducer,
  jobOpportunitiesReducer,
  settingReducer,
  BookingReducer,
  Promocode,
  FaqReducer
});

export default reducers;
