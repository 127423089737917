import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { MdOutlineClose } from 'react-icons/md'
import { ToastContainer } from 'react-toastify'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { updateIndusry, createIndustry } from '../../redux/actions/IndustryAction'
import {getAllPromoCodeActions}from '../../redux/actions/promoActions'
import { DatePicker, Space } from 'antd'
import { createPromocodeApi, getAllPromocode } from '../../api/promocodeApi'
import { displayErrorToast, displaySuccessToast } from '../../helper/toast_notification_function'
import  {SelectDropdownComponent}  from '../../components/SelectDropdownComponent'
const { RangePicker } = DatePicker
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}))

const BootstrapDialogTitle = props => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500]
                    }}
                >
                    <MdOutlineClose />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

export const AddPromoCode = ({ open, closeClickHandle, editState }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [ImgUpdate, setImgUpdate] = React.useState("")
    const [formData, setformData] = React.useState({})
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true)

    const BookingState = useSelector(state => state.BookingReducer)
    function swapFirstToLast(arr) {
        if (arr.length < 2) {
          // If the array has less than 2 elements, no need to swap.
          return arr;
        }
      
        const firstItem = arr.shift(); // Remove the first item and store it
        arr.push(firstItem); // Add the first item to the end
      
        return arr;
      }


    


console.log(BookingState?.bookingDiscountType)


    const BookingTypeDiscountMenu = React.useMemo(() => {
        const NonSwip=  BookingState?.bookingDiscountType?.map((ele) => {
          return {
            value: parseInt(ele.id),
            caption: ele.title,
          };
        })   ;


       return swapFirstToLast(NonSwip)
      }, []);

    React.useEffect(() => {
        setSubmitButtonDisabled(true)
    }, [editState])

    const accessToken = useSelector(
        state => state.userTokensReducer.tokens.accessToken
    )

    const closeHandle = () => {
        setSubmitButtonDisabled(true)
        setImgUpdate("")
        closeClickHandle()
    }

    const submitDataHandle = e => {
        e.preventDefault()
        if (formData.start_date == null) {
            displayErrorToast("Please select Start Date");
        }
        else if (formData.end_date == null) {
            displayErrorToast("Please select End Date");
        }
        else if (formData.name.length <= 5) {
            displayErrorToast("Name must be greater than 5");
        }
        else if (editState) {
            formData.id = editState.id
            updateIndusry(formData, accessToken, dispatch, navigate)
            closeClickHandle()
            setformData({})
        } else {
            createPromocodeApi(formData, accessToken, dispatch, navigate).then((res) => {
                if (res.data.success) {
                    displaySuccessToast("PromoCode Created Successfully");
                    getAllPromoCodeActions(dispatch,accessToken,navigate)
                }
                else {
                    displayErrorToast(res.data.message[0])
                }
            }).catch((res)=>{
                displayErrorToast(res.response.data.message[0])
            })
            closeClickHandle()
            setformData({})
        }
    }
    const onChangeHandle = e => {
        let name = e.target.name
        let val = e.target
        if (e.target.type === 'number') {
            setformData({ ...formData, [name]: parseInt(val.value) })
        } else {
            setformData({ ...formData, [name]: val.value })
        }
    }
    const handleDates = (range) => {
        setformData({ ...formData, start_date: range[0].$d, end_date: range[1].$d })
    };

    React.useEffect(() => {
        if (editState) {
            setImgUpdate(process.env.REACT_APP_ADMIN_IMAGE + editState.thumbnail)
        }
    }, [editState])

    return (
        <div>
            <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
                <BootstrapDialogTitle
                    id='customized-dialog-title'
                    onClose={closeHandle}
                >
                    {editState ? 'Update Promo Code ' : 'Add Promo  Code '}
                </BootstrapDialogTitle>
                <form
                    autoComplete='off'
                    encType='multipart/form-data'
                    onSubmit={e => submitDataHandle(e)}
                >
                    <DialogContent dividers sx={{ maxHeight: '700px' }}>
                        <TextField
                            defaultValue={editState ? editState.title : ''}
                            name='name'
                            // value={Price}
                            sx={allStyles.modalFields}
                            label='Name'
                            type='text'
                            variant='outlined'
                            fullWidth
                            onChange={e => onChangeHandle(e)}
                            required
                        />
                        <RangePicker
                            onChange={(range) => { handleDates(range) }}
                        />
                       <SelectDropdownComponent
                name="category_id"
                caption="category"
                sectionTitle="category"
                onChangeHandle={onChangeHandle}
                menuItems={BookingTypeDiscountMenu}
                defaultval={editState ? editState.category: ""}
              />
                        <TextField
                            name='discount_percentage'
                            // value={Price}`
                            sx={allStyles.modalFields}
                            type='number'
                            label='Discount Percentage'
                            inputProps={{
                                min: 0, // This will prevent negative values
                              }}
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={e => onChangeHandle(e)}
                            required
                        />
                        <TextField
                            name='discount_days'
                            sx={allStyles.modalFields}
                            type='number'
                            label='Discount Days'
                            inputProps={{
                                min: 0, // This will prevent negative values
                              }}
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={e => onChangeHandle(e)}
                            required
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button
                            type='submit'
                            sx={{ ...allStyles.actionsButtons, mx: 2, my: 1 }}
                            variant='outlined'
                        >
                            {editState ? 'Update' : 'Create'}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
            <ToastContainer />
        </div>
    )
}
