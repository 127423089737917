import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { MdOutlineClose } from 'react-icons/md'
import { ToastContainer } from 'react-toastify'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField
} from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {updateBookingAction}from '../../redux/actions/BookingActions'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const AddUpdateBooking = ({ open, closeClickHandle, editState }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formData, setformData] = React.useState({})
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true)
  
  React.useEffect(() => {
    setSubmitButtonDisabled(true)
  }, [editState])


  React.useEffect(()=>{
    setformData({...editState})
  },[editState])
  
  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )

  const closeHandle = () => {
    setSubmitButtonDisabled(true)
    closeClickHandle()
  }

  const submitDataHandle = e => {
    e.preventDefault()
    if (editState) {
      formData.id = editState.id
      console.log(formData,'formData')
      updateBookingAction(formData, accessToken, dispatch, navigate)
      closeClickHandle()
      setformData({})
    }
  }
  const onChangeHandle = e => {
    let name = e.target.name
    let val = e.target
    if (name === 'is_discount') {
      setformData({ ...formData, [e.target.name]: val.checked })
    }  else if(name==='charges_per_day'|| name==='minimum_days')  {
      setformData({ ...formData, [name]:  Number(val.value) })
    }else{
      setformData({ ...formData, [name]:  val.value })
    }
    setSubmitButtonDisabled(false)
  }

  return (
    <div>
      <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          Update Booking Type
        </BootstrapDialogTitle>
        <form
          autoComplete='off'
          encType='multipart/form-data'
          onSubmit={e => submitDataHandle(e)}
        >
          <DialogContent dividers sx={{ maxHeight: '700px' }}>
            <TextField
              defaultValue={editState ? editState.title : ''}
              name='title'
              // value={Price}
              sx={allStyles.modalFields}
              label='Title'
              type='text'
              variant='outlined'
              fullWidth
              onChange={e => onChangeHandle(e)}
              required
            />
             <TextField
              defaultValue={editState ? editState.description : ''}
              name='description'
              // value={Price}
              sx={allStyles.modalFields}
              label='Description'
              type='text'
              variant='outlined'
              fullWidth
              onChange={e => onChangeHandle(e)}
              required
            />
             <TextField
              defaultValue={editState ? editState.minimum_days : ''}
              name='minimum_days'
              // value={Price}
              sx={allStyles.modalFields}
              label='Minimum Days'
              type='number'
              variant='outlined'
              inputProps={{
                min: 0, // This will prevent negative values
              }}
              fullWidth
              onChange={e => onChangeHandle(e)}
              required
            />
             <TextField
              defaultValue={editState ? editState.charges_per_day : ''}
              name='charges_per_day'
              // value={Price}
              sx={allStyles.modalFields}
              label='Charges Per Day'
              type='number'
              inputProps={{
                min: 0, // This will prevent negative values
              }}
              variant='outlined'
              fullWidth
              onChange={e => onChangeHandle(e)}
              required
            />
             <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
        name='is_discount'
          control={
            <Switch checked={formData?.is_discount}   onChange={(e)=>onChangeHandle(e)}   name='is_discount'/>
          }
          label="Discount"
        />
      </FormGroup>
    </FormControl>
          </DialogContent>
          <DialogActions>
            <Button

              disabled={submitButtonDisabled}
              type='submit'
              sx={{...allStyles.actionsButtons, mx: 2, my: 1 }}
              variant='outlined'
            >
              {editState ? 'Update Booking Type' : 'Create Booking Type'}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}
