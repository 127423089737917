import { getSettingApi, updateSettingApi } from "../../api/settingApi";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";
import { saveTags, startGettingTagsData } from "../constants/setting_constant";

export const getAllSetting = (dispatch, token, navigate) => {
  dispatch({
    type: startGettingTagsData,
  });
  getSettingApi(token, dispatch, navigate)
    .then((e) => {
      return dispatch({
        type: saveTags,
        payload: e.data.data ? e.data.data : [],
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateSetting = (data, token, dispatch, navigate) => {
  updateSettingApi(data, token, dispatch, navigate)
    .then((res) => {
      displaySuccessToast(`Setting Updated successfully!`);
      getAllSetting(dispatch, token, navigate);
    })
    .catch((error) => {
      displayErrorToast(error);
    });
};
