import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { TextField, Typography } from "@mui/material";
import { allStyles } from "../../allStyles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createTag,
  getAllTags,
  updateSetting,
} from "../../redux/actions/SettingAction";
import { margin } from "@mui/system";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const AddUpdateSetting = ({ open, closeClickHandle, editState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setformData] = React.useState({});
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    setSubmitButtonDisabled(true);
  }, [editState]);

  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );

  const closeHandle = () => {
    setSubmitButtonDisabled(true);
    closeClickHandle();
  };

  const submitDataHandle = (e) => {
    e.preventDefault();
    if (editState) {
      formData.id = editState.id;
      updateSetting(formData, accessToken, dispatch, navigate);
      closeClickHandle();
      setformData({});
    }
    // else {
    //   createTag(formData, accessToken, dispatch, navigate)
    //   closeClickHandle()
    //   setformData({})
    // }
  };

  const onChangeHandle = (e) => {
    let name = e.target.name;
    let val = e.target;
    setformData({ ...formData, [name]: val.value });
    setSubmitButtonDisabled(false);
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          className="mx-5"
          id="customized-dialog-title"
          onClose={closeHandle}
        >
          {editState?.key?.replace(/_/g, " ")}
          {/* {editState ? "Update Settting" : "Add Tag"} */}
        </BootstrapDialogTitle>
        <form
          autoComplete="off"
          encType="multipart/form-data"
          onSubmit={(e) => submitDataHandle(e)}
        >
          <Typography variant="body2"> </Typography>
          <DialogContent dividers sx={{ maxHeight: "700px" }}>
            <TextField
              defaultValue={editState ? editState.value : ""}
              name="value"
              // value={Price}
              sx={allStyles.modalFields}
              label="Value"
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                  step: "any",
                },
              }}
              variant="outlined"
              fullWidth
              onChange={(e) => onChangeHandle(e)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitButtonDisabled}
              type="submit"
              sx={{ ...allStyles.actionsButtons, mx: 2, my: 1 }}
              variant="outlined"
            >
              {editState ? "Update Setting" : "Create Setting"}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  );
};
