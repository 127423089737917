import {getBookingApi,updateBookingApi,getBookingTypeDiscountApi} from "../../api/BookingApi";
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import {
saveBooking,startGettingBookingData,
SaveBookingDiscountType
} from "../constants/Booking_constants";
export const getAllBookingAction = (dispatch, token, navigate) => {
  dispatch({
    type: startGettingBookingData,
  });
  getBookingApi(token,dispatch,navigate).then((e) => {
    return dispatch({
      type: saveBooking,
      payload: e.data.data ? e.data.data : [],
    });
  });
};


  export const updateBookingAction = (data, token, dispatch ,navigate) => {
    updateBookingApi(data, token, dispatch ,navigate)
      .then((res) => {
          displaySuccessToast(`Booking Updated successfully!`)
          getAllBookingAction(dispatch,token,navigate)
        })
        .catch(error => {
          displayErrorToast(error)
        })
  }
  



export const getBookingTypeDiscountAction=(dispatch, token, navigate)=>{
  dispatch({
    type: startGettingBookingData,
  });
  getBookingTypeDiscountApi(token,dispatch,navigate).then((e)=>{
    return dispatch({
      type: SaveBookingDiscountType,
      payload: e.data.data ? e.data.data : [],
    });
  }).catch((error)=>{
    console.log(error)
  })
}




