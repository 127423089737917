import React from "react";
import { Stack, IconButton, Typography, Box } from "@mui/material";
import { GetUserProfile_action } from "../redux/actions/UserAction";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import checkmark from '../assets/images/checkMark.png'
import { PageLoading } from "../assets/PageLoading";
const UserProfile = () => {
  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { get_Profile, isLoading_profile } = useSelector(
    (state) => state.eventsReducer
  );

  const { user_level_two_profile } = get_Profile;
  console.log(user_level_two_profile)
  useEffect(() => {
    GetUserProfile_action(accessToken, dispatch, navigate, id);
  }, []);

  return (
    <>
      {isLoading_profile ? (
        <PageLoading />
      ) : (
        <>
          <Stack
            sx={{ px: 3, mb: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              onClick={() => {
                navigate("/user");
              }}
              aria-label="edit"
            >
              <AiOutlineArrowLeft color="#2f6deb" />
              <Typography sx={{ mx: 1 }} variant="h6" color="#2f6deb">
                Back
              </Typography>
            </IconButton>
          </Stack>
          <div className="profile_card">
            <h1 className="text-center  Profile_heading ">User Information</h1>
          </div>


          <Box
            className="profile_card"
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
          >
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >First Name : </span>    {get_Profile.first_name}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Last Name : </span>    {get_Profile.last_name}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Email: </span>    {get_Profile.email}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >City : </span>    {get_Profile.city}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >State : </span>    {get_Profile.state}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Zip : </span>    {get_Profile.zip}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Country : </span>    {get_Profile.country}
              </h3>
            </Box>
          </Box>
          {user_level_two_profile&&<>

{get_Profile["level_two_profile_status"] === "none" ? null : (
            <div className="profile_card card_separater d-flex justify-content-end ">
              <div className="d-flex align-items-center justify-content-between w-75">
                <h1 className="text-center Profile_heading extra_margin">
                  Level Two Information
                </h1>
                {get_Profile["level_two_profile_status"] ===
                  "none" ? null : get_Profile["level_two_profile_status"] ===
                    "valid" ? (
                  <p className="h4 status_profile">
                    <span
                      class="badge badge-danger bg-success mx-3"
                      style={{
                        opacity: 0.9,
                        boxShadow: "2px 1px 13px -1px green",
                      }}
                    >
                      Valid
                    </span>
                  </p>
                ) : (
                  <p className="h6 status_profile">
                    <span
                      class="badge badge-danger bg-danger mx-3"
                      style={{ boxShadow: "4px 1px 13px -1px #red" }}
                    >
                      In Valid
                    </span>
                  </p>
                )}
              </div>
            </div>
          )}

          {get_Profile["level_two_profile_status"] === "none" ? null : (
            <Box
              className="profile_card"
              mt="10px"
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)">
              
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Name Of Investor : </span>    {user_level_two_profile?.name_of_investor ? user_level_two_profile?.name_of_investor : "---"}
                </h3>
              </Box>
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Investor Type : </span>    {user_level_two_profile?.investor_type ? user_level_two_profile?.investor_type : "---"}
                </h3>
              </Box>
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Name Of Signing Party : </span>    {user_level_two_profile?.name_of_signing_party ? user_level_two_profile?.name_of_signing_party : "---"}
                </h3>
              </Box>
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Title Of Signing Party : </span>    {user_level_two_profile?.title_of_signing_party ? user_level_two_profile?.title_of_signing_party : "---"}
                </h3>
              </Box> 
               <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Address : </span>    {user_level_two_profile?.address ? user_level_two_profile?.address : "---"}
                </h3>
              </Box>
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Email : </span>    {user_level_two_profile?.email ? user_level_two_profile?.email : "---"}
                </h3>
              </Box>
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Date Signed : </span>    {user_level_two_profile?.date_signed ? user_level_two_profile?.date_signed : "---"}
                </h3>
              </Box>
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Contact Number : </span>    {user_level_two_profile?.contact_number ? user_level_two_profile?.contact_number : "---"}
                </h3>
              </Box>
              <Box
                gridColumn='span 6'
                gridRow='span 1'
              >
                <h3 className="get_heading">
                  <span className="text-muted" >Signture </span>   <img height={200}   className="mx-5"  width={200} src={user_level_two_profile?.signature }  /> 
                </h3>
              </Box>
            </Box>
          )}</>}
        </>
      )}
    </>
  );
};

export default UserProfile;
