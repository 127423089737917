import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  IconButton,
  Button,
} from "@mui/material";
import "./Opportunities.css";
import { PaginationSkeleton } from "../../assets/PaginationSkeleton";
import { RefressButton } from "../../assets/buttons/RefressButton";
import { AddButton } from "../../assets/buttons/AddButton";
import { AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TableBodyDataSkeleton } from "../../assets/TableBodyDataSkeleton";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";
import { allStyles } from "../../allStyles";
import {
  getAllJobOpportunities,
  getCompanyProfile,
  ClearCompanyProfile,
} from "../../redux/actions/jobOpportunitiesAction";
import { formatDate } from "../../helper/date_format_function";
import { NoDataSetMessage } from "../../components/NoDataSetMessage";
import { FcAlphabeticalSortingAz } from 'react-icons/fc';


const columns = [
  {
    id: "serialNumber",
    label: "S#",
    minWidth: 20,
    align: "center",
    sortAction: false,
  },
  {
    id: "logo",
    label: "Logo",
    minWidth: 170,
    align: "center",
    sortAction: true,
  },
  {
    id: "company_name",
    label: "Company Name",
    minWidth: 170,
    align: "center",
    sortAction: true,
  },

  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "center",
    sortAction: true,
  },
  {
    id: "industries",
    label: "Industry",
    minWidth: 170,
    align: "center",
    sortAction: true,
  },
  {
    id: "Actions",
    label: "Actions",
    minWidth: 170,
    align: "center",
    sortAction: false,
  },
];

export const Opportunities = () => {
  const [StartDate, SetStartDate] = useState("");
  const [startEnd, SetStartEnd] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState("ASC");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [selectedDataForDelete, setSelectedDataForDelete] =
    React.useState(null);
  const [selectedDataForEdit, setSelectedDataForEdit] = React.useState(null);

  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );
  const jobOpportunitiesState = useSelector(
    (state) => state.jobOpportunitiesReducer
  );
  console.log(jobOpportunitiesState, "  job OpportuniteisState ==========>");

  const sortingColumns = (col) => {
    if (orderData === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setRows([...sorted]);
      setOrderData("DSC");
    } else if (orderData === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setRows([...sorted]);
      setOrderData("ASC");
    } else {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setRows([...sorted]);
      setOrderData("ASC");
    }
  };

  useEffect(() => {
    setLoading(jobOpportunitiesState.isLoading);
    setRows(jobOpportunitiesState.jobOpportunities);
  }, []);

  useEffect(() => {
    setLoading(jobOpportunitiesState.isLoading);
    setRows(jobOpportunitiesState.jobOpportunities);
  }, [jobOpportunitiesState]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Modal open handle through add button
  // Modal Close Handle through modal cross button

  const refreshHandle = () => {
    getAllJobOpportunities(dispatch, accessToken, navigate);
  };
  return (
    <Box>
      <Stack
        sx={{ px: 1, mb: 1 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h5">Company</Typography>
        </Stack>

        <Stack spacing={3} direction="row" alignItems="center">
          {/* <RefressButton disabled={loading} onClickHandle={refreshHandle} /> */}
          {/* <Stack alignItems={"center"}>
            <AddButton onClickHandle={addHandle} caption="Product" />
          </Stack> */}
        </Stack>
      </Stack>

      <Stack>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={allStyles.tableHeight}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.sortAction ? (
                        <Button
                          sx={allStyles.actionsButtons}
                          onClick={() => sortingColumns(column.id)}
                        >

                          {column.label}
                          <FcAlphabeticalSortingAz size={20} style={{ marginLeft: "15px" }} />

                        </Button>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loading ? (
                <TableBodyDataSkeleton
                  numberOfColumns={columns.length}
                  imagePreview={true}
                  editAction={true}
                  deleteAction={true}
                />
              ) : rows && rows.length !== 0 ? (
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                          {columns.map((column, ind) => {
                            const value = row[column.id];
                            return column.id === "logo" ? (
                              <TableCell key={ind} align={column.align}>
                                <Box sx={{ width: "100px" }}>
                                  <img
                                    src={value}
                                    alt=""
                                    width="100%"
                                  />
                                </Box>
                              </TableCell>
                            ) : column.id === "serialNumber" ? (
                              <TableCell key={ind} align={column.align}>
                                {page < 1
                                  ? i + 1
                                  : page * rowsPerPage + (i + 1)}
                              </TableCell>
                            ) : column.id === "industries" ? (
                              <TableCell key={ind} align={column.align}>
                                {value[0]?.title ? value[0]?.title : "--"}
                              </TableCell>
                            ) : column.id === "Actions" ? (
                              <TableCell key={ind} align={column.align}>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  justifyContent="center"
                                >
                                  <IconButton
                                    title="View Profile"
                                    aria-label="edit"
                                    onClick={() => {
                                      ClearCompanyProfile(dispatch);
                                      getCompanyProfile(
                                        dispatch,
                                        accessToken,
                                        navigate,
                                        row.id
                                      );
                                      navigate(`/company_profile/${row.id}`);
                                    }}
                                  >
                                    <AiOutlineUser color="#2f6deb" />
                                  </IconButton>

                                  {/* <IconButton
                                    onClick={() => deleteHandle(row)}
                                    aria-label="delete"
                                  >
                                    <RiDeleteBin6Line color="#fb8484" />
                                  </IconButton> */}
                                </Stack>
                              </TableCell>
                            ) : (
                              <TableCell key={ind} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <NoDataSetMessage lengthOfColumns={columns.length} />
              )}
            </Table>
          </TableContainer>

          {loading ? (
            <PaginationSkeleton />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Stack>
      <ToastContainer />
    </Box>
  );
};
