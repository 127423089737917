import { apiHandle } from "./apiHandler";
import {dataWithFiles}from '../helper/common_functions'

// Get All Booking Api
export const getBookingApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get("/auth/bookingType");
};




  // Update Booking Api
  export const updateBookingApi = async (data, token, dispatch, navigate) => {
    return await apiHandle(token, dispatch, navigate).put(`/auth/bookingType/${data.id}`,data)
  }
  



export const getBookingTypeDiscountApi=async(token,dispatch,navigate)=>{
  return await apiHandle(token,dispatch,navigate).get('/auth/bookingTypesForDiscount')
}

