import React from "react";
import { Stack, IconButton, Typography, Box } from "@mui/material";
import {getAllContactIDAction}from '../../redux/actions/ContactAction'
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoading } from "../../assets/PageLoading";
import {formatDateTime}from '../../helper/date_format_function'
const UserProfile = () => {
  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {contact,isLoadingContact}=useSelector((state)=>state.tagsReducer)
  console.log(contact)
  useEffect(() => {
    getAllContactIDAction(dispatch,id,accessToken,navigate );
  }, []);

  return (
    <>
      {isLoadingContact ? (
        <PageLoading />
      ) : (
        <>
          <Stack
            sx={{ px: 3, mb: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              onClick={() => {
                navigate("/user");
              }}
              aria-label="edit"
            >
              <AiOutlineArrowLeft color="#2f6deb" />
              <Typography sx={{ mx: 1 }} variant="h6" color="#2f6deb">
                Back
              </Typography>
            </IconButton>
          </Stack>
          <div className="profile_card">
            <h1 className="text-center  Profile_heading ">Contact Information</h1>
          </div>


          <Box
            className="profile_card"
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
          >
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >User Name : </span>    {contact?.name}
              </h3>
            </Box>
       
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Contact No : </span>    {contact?.contact_number}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Email: </span>    {contact?.email}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Date & Time : </span> {formatDateTime(contact?.created_at)}
              </h3>
            </Box>
          
            {/* <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Message : </span>    {contact.message}
              </h3>
            </Box> */}
            {/* <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Country : </span>    {get_Profile.country}
              </h3>
            </Box> */}
          </Box>
        </>
      )}
      <Stack mt={3}  >
      <div className="profile_card">
            <h1 className="text-center  Profile_heading ">Query Information</h1>
          </div>
      </Stack>

      <Box
            className="profile_card"
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
          >
              <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Purpose : </span>    {contact?.purpose}
              </h3>
            </Box>
            <Box
              gridColumn='span 12'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Message : </span>    {contact?.message}
              </h3>
            </Box>
            {/* <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Message : </span>    {contact.message}
              </h3>
            </Box> */}
            {/* <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Country : </span>    {get_Profile.country}
              </h3>
            </Box> */}
          </Box>
  

    </>
  );
};

export default UserProfile;
