import { apiHandle } from "./apiHandler";

// Get All PromoCode Api
export const getAllPromocode = async (token, dispatch, navigate) => {
    return await apiHandle(token, dispatch, navigate).get("/auth/promoCode");
};

// Update Category Api
export const togglePromocodeApi = async (id, token, dispatch, navigate) => {
    return await apiHandle(token, dispatch, navigate).put(`/auth/promoCode/status/${id}`)
}

// Add Category Api
export const createPromocodeApi = async (data, token, dispatch, navigate) => {
    return await apiHandle(token, dispatch, navigate).post('/auth/promoCode', data)
}

