import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo/logo.png";
import whiteLogo from "../assets/images/logo/logo.png";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
// import UserProfile from "./UserProfile";
import { User, Industry, Contact, Opportunities, Setting, Booking, Faq } from "./index";
import CompanyProfile from "./CompanyProfile";
import {
  Stack,
  Typography,
  SvgIcon,
  MenuItem,
  Divider,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FiMenu } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsGrid, BsCalendar4Event } from "react-icons/bs";
import {
  AiOutlineUnorderedList,
  AiOutlineBuild,
  AiOutlineUser,
  AiFillSetting,
  AiFillBank,
  AiFillLock
} from "react-icons/ai";
import { HiOutlineTicket } from "react-icons/hi"
import { FaFileCircleQuestion } from "react-icons/fa6";
import { logoutUser, RemoveTokens } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { LogoutButton } from "../assets/buttons/LogoutButton";
import { getAllIndustry } from "../redux/actions/IndustryAction";
import { getAllUser } from "../redux/actions/UserAction";
import { getAllContact } from "../redux/actions/ContactAction";
import { getAllJobOpportunities } from "../redux/actions/jobOpportunitiesAction";
import { getAllSetting } from "../redux/actions/SettingAction";
import { getAllBookingAction, getBookingTypeDiscountAction } from '../redux/actions/BookingActions'
import ContactDetail from '../container/Contact/contactDetails'
import { getAllPromoCodeActions } from '../redux/actions/promoActions'
import { getAllFaqAction } from '../redux/actions/FaqActions'
import { BsBookmarkDashFill } from 'react-icons/bs'
import UserProfile from "./UserProfile";
import Promocode from "./PromoCode/Promocode";
import ChangePassword from "./ChangePassword/ChangePassword";
let textColor = "#1D3557";
export const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sideBarActive, setSideBarActive] = useState(true);
  const userStatus = useSelector((state) => state.userStateReducer.user);
  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );
  const dispatch = useDispatch();
  let logoutHandle = () => {
    logoutUser(dispatch);
    navigate("/");
  };
  useEffect(() => {
    getAllUser(dispatch, accessToken, navigate);
    getAllIndustry(dispatch, accessToken, navigate);
    getAllContact(dispatch, accessToken, navigate);
    getAllJobOpportunities(dispatch, accessToken, navigate);
    getAllSetting(dispatch, accessToken, navigate);
    getAllBookingAction(dispatch, accessToken, navigate);
    getBookingTypeDiscountAction(dispatch, accessToken, navigate)
    getAllPromoCodeActions(dispatch, accessToken, navigate)
    getAllFaqAction(dispatch, accessToken, navigate)
  }, []);

  useEffect(() => {
    if (!userStatus) {
      RemoveTokens(dispatch);
    }
  }, [userStatus]);
  let listOfMenuItems = [
    {
      caption: "Contacts",
      linkTo: "",
      icon: <BsCalendar4Event size={23} />,
      element: <Contact />,
      Tooltip: "Contact from List",
    },
    {
      caption: "Users",
      linkTo: "user",
      icon: <AiOutlineUser size={23} />,
      element: <User />,
      Tooltip: "Users List",
    },
    {
      caption: "Industry",
      linkTo: "Industry",
      icon: <AiOutlineUnorderedList size={23} />,
      element: <Industry />,
    },
    {
      caption: "Faq",
      linkTo: "faq",
      icon: <FaFileCircleQuestion size={23} />,
      element: <Faq />,
    },
    {
      caption: "Company",
      linkTo: "company",
      icon: <AiFillBank size={23} />,
      element: <Opportunities />,
      Tooltip: "Companies List",
    },
    {
      caption: "Setting",
      linkTo: "setting",
      icon: <AiFillSetting size={23} />,
      element: <Setting />,
      Tooltip: "Companies List",
    },
    {
      caption: "Booking Type",
      linkTo: "booking",
      icon: <BsBookmarkDashFill size={23} />,
      element: <Booking />,
      Tooltip: "Companies List",
    },
    {
      caption: "Promo Code",
      linkTo: "promocode",
      icon: <HiOutlineTicket size={23} />,
      element: <Promocode />,
      Tooltip: "Promo Codes",
    },
    {
      caption: "Change Password",
      linkTo: "/changepassword",
      icon: <AiFillLock />,
      element: <ChangePassword />,
      Tooltip: "Change Password",
    },
    {
      caption: "",
      linkTo: "/profile/:id",
      icon: <AiOutlineUnorderedList />,
      element: <UserProfile />,
      nonVisiable: true,
    },
    {
      caption: "",
      linkTo: "/contact/:id",
      icon: <AiOutlineUnorderedList />,
      element: <ContactDetail />,
      nonVisiable: true,
    },
    {
      caption: "",
      linkTo: "/company_profile/:id",
      icon: <AiOutlineUnorderedList />,
      element: <CompanyProfile />,
      nonVisiable: true,
    }
  ];
  // const NavLinkStyle=({isActive})=>{
  //   if(isActive){

  //   }
  // }

  return (
    <>
      <Stack>
        <Stack className={`sideBar ${sideBarActive ? "active" : "not_Active"}`}>
          <Stack sx={{ py: 1 }}>
            <Stack direction="row" alignItems="center" sx={{ mt: "10px" }}>
              {sideBarActive ? (
                <Stack sx={{ px: 3, transition: "all 0.5s ease-in-out", display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <img height='50px' width="50px" src={logo} />
                  <Typography mt={1} fontSize={18} color={'white'}   >Investor Presentations</Typography>
                </Stack>
              ) : (
                <Stack sx={{ px: "2px" }} mx={"auto"}  >
                  <img height={50} width={50} style={{ textAlign: "center" }} src={whiteLogo} />
                </Stack>
              )}
            </Stack>
            <Stack sx={{ px: 3, mt: "10px" }}>
              <Divider
                sx={{ backgroundColor: "gray" }}
                orientation="horizontal"
                variant="fullWidth"
                light
              />
            </Stack>
            <Stack
              sx={{ px: 3, py: 1 }}
              direction="column"
              spacing={4}
            >
              {listOfMenuItems.map((e, i) =>
                e.nonVisiable ? null : (
                  <>
                    <Link
                      key={i}
                      className={`${location.pathname === `/${e.linkTo}`
                        ? "selected text-center"
                        : " text-decoration-none "
                        }`}
                      to={e.linkTo}
                    >
                      <MenuItem
                        disableGutters
                        dense
                        key={i}
                        className="menuItem"
                        autoFocus={false}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          {sideBarActive ? (
                            <SvgIcon
                              className="svg_icon"
                              sx={{
                                overflow: "initial",
                                fontSize: 23,
                                marginRight: "25px",
                              }}
                            >
                              {e.icon}
                            </SvgIcon>
                          ) : (
                            <Tooltip title={e.Tooltip ? e.Tooltip : null}>
                              <SvgIcon
                                className="svg_icon"
                                sx={{
                                  overflow: "initial",
                                  fontSize: 23,
                                  marginRight: "25px",
                                }}
                              >
                                {e.icon}
                              </SvgIcon>
                            </Tooltip>
                          )}
                          <Stack>
                            <Typography
                              sx={{ fontSize: 20, fontWeight: "light" }}
                            >
                              {e.caption}
                            </Typography>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    </Link>
                  </>
                )
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack className="pages">
          <Paper
            elevation={2}
            sx={{ px: 3, py: 1, mb: 1, borderRadius: 0 }}
            children={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {sideBarActive ? (
                  <Stack direction="row" alignItems="center">
                    <IconButton
                      color="primary"
                      onClick={() => setSideBarActive(!sideBarActive)}
                    >
                      <IoIosArrowBack color={textColor} size={25} />
                    </IconButton>
                  </Stack>
                ) : (
                  <Stack direction="row" alignItems="center">
                    <IconButton
                      color="primary"
                      onClick={() => setSideBarActive(!sideBarActive)}
                    >
                      <FiMenu color={textColor} size={25} />
                    </IconButton>
                  </Stack>
                )}
                <Stack>
                  <LogoutButton onClickHandle={logoutHandle} />
                </Stack>
              </Stack>
            }
          />
          <Stack sx={{ px: 3 }}>
            <Routes>
              {listOfMenuItems.map((e, i) => (
                <Route key={i} path={e.linkTo} element={e.element} />
              ))}
            </Routes>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
