import { Box, Button, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { allStyles } from '../../allStyles'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { displayErrorToast, displaySuccessToast } from '../../helper/toast_notification_function'
import { Change_password } from '../../redux/actions/UserAction'
import { useNavigate } from 'react-router-dom'
import { apiHandle } from '../../api/apiHandler'
import { logoutUser } from '../../redux/actions/userActions'

const ChangePassword = () => {
    const accessToken = useSelector(
        (state) => state.userTokensReducer.tokens.accessToken
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [processLoading, setProcessLoading] = useState(false);
    const [olhidden, setolhidden] = useState(true);
    const [nwhidden, setnwhidden] = useState(true);

    let changepassword = (e) => {
        e.preventDefault();
        if (!data.new_password || !data.old_password) {
            displayErrorToast("Please fill the input fields!");
        } else {
            setProcessLoading(true);
            apiHandle(accessToken, dispatch, navigate).post('/auth/changePassword', data).then((res) => {
                setProcessLoading(false);
                displaySuccessToast("Password Changed Successfully");
                navigate("/");
                logoutUser(dispatch);
            })
                .catch((error) => {
                    setProcessLoading(false);
                    displayErrorToast(error);

                });
        }
    };
    return (

        <Box>
            <Stack
                sx={{ px: 1, mb: 2 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack>
                    <Typography variant="h5">Change Password</Typography>
                </Stack>
            </Stack>

            <Stack
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={allStyles.loginPage}
            >
                <Paper
                    square
                    elevation={2}
                    children={
                        <Stack
                            sx={allStyles.loginBox}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Stack
                                alignItems="center"
                                spacing={3}
                                direction="column"
                                component="form"
                                sx={{
                                    "& > :not(style)": { width: "35ch" },
                                    px: 4,
                                }}
                                noValidate
                                autoComplete="off"
                                onSubmit={changepassword}
                            >
                                <Stack spacing={3}>
                                    <div className='ch_pass_field'>
                                        <label>
                                            <span>
                                                Old Password
                                            </span>
                                            <input name="old_password" type={olhidden ? "password" : "text"} onChange={(e) => {
                                                e.preventDefault()
                                                setData({ ...data, [e.target.name]: e.target.value })
                                            }} />
                                        </label>
                                        <div className='eye-ic' onClick={() => { setolhidden(!olhidden) }}>
                                            {olhidden ? <AiFillEye color='#aaa' /> : <AiFillEyeInvisible color='#aaa' />}
                                        </div>
                                    </div>
                                    <div className='ch_pass_field'>
                                        <label>
                                            <span>
                                                New Password
                                            </span>
                                            <input name="new_password" type={nwhidden ? "password" : "text"} onChange={(e) => {
                                                e.preventDefault()
                                                setData({ ...data, [e.target.name]: e.target.value })
                                            }} />
                                        </label>
                                        <div className='eye-ic' onClick={() => { setnwhidden(!nwhidden) }}>
                                            {nwhidden ? <AiFillEye color='#aaa' /> : <AiFillEyeInvisible color='#aaa' />}
                                        </div>
                                    </div>
                                    <Stack
                                        direction="row"
                                        sx={{ my: 3, position: "relative" }}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Button
                                            type="submit"
                                            disabled={processLoading}
                                            sx={allStyles.loginButton}
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                        >
                                            {processLoading ? "" : "Change Password"}
                                        </Button>
                                        {processLoading ? (
                                            <Stack sx={{ position: "absolute" }}>
                                                {" "}
                                                <CircularProgress size={20} disableShrink />
                                            </Stack>
                                        ) : null}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    }
                />

                <ToastContainer />
            </Stack>
        </Box>
    )
}

export default ChangePassword