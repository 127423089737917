export const saveEvents = "SAVE_EVENTS";
export const startGettingEventsData = "START_GETTING_EVENTS_DATA";

export const createdNewEventType = "CREATED_NEW_EVENT_TYPE";
export const updateEventType = "UPDATE_EVENT_TYPE";
export const deleteEventType = "DELETE_EVENT_TYPE";

export const GetUserProfile = "GETUSER_PROFILE";

export const ClearUserProfile = "CLEAR_GET_PROFILE";
