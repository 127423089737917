import {createPromocodeApi,getAllPromocode,togglePromocodeApi}from '../../api/promocodeApi'
import {savepromo_Code,startGettingpromo_CodeData}from '../constants/promo_Constants'

export const getAllPromoCodeActions=(dispatch,token,navigate)=>{
    dispatch({
        type:startGettingpromo_CodeData
    })
    getAllPromocode(token,dispatch,navigate).then((res)=>{
        dispatch({
            type:savepromo_Code,
            payload:res.data?res.data.data:[]
        })
    })
}