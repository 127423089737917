import { getContact,getContactByIdApi } from "../../api/ContactApi";
import { saveTags, startGettingTagsData,SaveByIdContact } from "../constants/tags_constants";
export const getAllContact = (dispatch, token, navigate) => {
  dispatch({
    type: startGettingTagsData,
  });
  getContact(token, dispatch, navigate).then((e) => {
    return dispatch({
      type: saveTags,
      payload: e.data.data ? e.data.data : [],
    });
  });
};

export const getAllContactIDAction = (dispatch,data, token, navigate) => {
  dispatch({
    type: startGettingTagsData,
  });
  getContactByIdApi(token,data, dispatch, navigate).then((e) => {
    console.log(e)
    return dispatch({
      type: SaveByIdContact,
      payload: e.data.data ? e.data.data[0] : [],
    });
  });
};
