import { apiHandle } from "./apiHandler";

// Get All Tags Api
export const getContact = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get("/auth/contact");
};


export const getContactByIdApi=async (token, data,dispatch, navigate)=>{
  return await apiHandle(token,dispatch, navigate).get(`/auth/contact/${data}`)
}