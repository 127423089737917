import {
  deleteFaqApi,
  createFaqApi,
  getFaqApi,
  updateFaqApi,
} from "../../api/FaqApi";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";
import { saveFaq, startGettingFaqData } from "../constants/Faq_constants";

export const getAllFaqAction = (dispatch, token, navigate) => {
  dispatch({
    type: startGettingFaqData,
  });
  getFaqApi(token, dispatch, navigate).then((e) => {
    console.log(e.data.data,'data from faq action')
    return dispatch({
      type: saveFaq,
      payload: e.data.data ? e.data.data : [],
    });
  });
};

export const createFaqAction = (data, token, dispatch, navigate) => {
  createFaqApi(data, token, dispatch, navigate)
    .then((res) => {
      displaySuccessToast(`Faq Created successfully!`);
      getAllFaqAction(dispatch, token, navigate);
    })
    .catch((error) => {
      displayErrorToast(error);
    });
};

export const updateFaqAction = (data, token, dispatch, navigate) => {
    updateFaqApi(data, token, dispatch, navigate)
    .then((res) => {
      displaySuccessToast(`Faq Updated successfully!`);
      getAllFaqAction(dispatch, token, navigate);
    })
    .catch((error) => {
      displayErrorToast(error);
    });
};
export const deleteFaqAction = (data, token, dispatch, navigate) => {
    deleteFaqApi(data, token, dispatch, navigate)
    .then((res) => {
      displaySuccessToast(`Faq Delete successfully!`);
      getAllFaqAction(dispatch, token, navigate);
    })
    .catch((error) => {
      displayErrorToast(error);
    });
};
