import { saveCategories, startGettingCategoriesData,GetCategoryItem,ClearCategoryItem } from "../../constants/categories_constants"
const INITIAL_STATE = {
    isLoading:true,
    categories:{
    },
    CategoryItem:[],
    Loading_CategoryItem:true
}
const categoriesReducer = (state = INITIAL_STATE,action) => {
    const {type, payload} = action
    switch (type) {
        case saveCategories:
            return {
                ...state,
                isLoading:false,
                categories:payload
            }
        case startGettingCategoriesData:
            return {
                ...state,
                isLoading:true,
            }
        case GetCategoryItem:
            return{
                ...state,
                Loading_CategoryItem:false,
                CategoryItem:payload
            }
        case ClearCategoryItem:
            return{
                ...state,
                CategoryItem:[],
                Loading_CategoryItem:true
            }
        default:
            return state
    }
}
export default categoriesReducer