import { apiHandle } from "./apiHandler";

// Get All Tags Api
export const getSettingApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get("/auth/setting");
};

// Create New Tag Api
// export const createTagApi = async (data, token, dispatch, navigate) => {
//     return await apiHandle(token, dispatch, navigate).post('/createTag',data)
//   }

// Update Tag Api
export const updateSettingApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/auth/setting/${data.id}`,
    { value: data.value }
  );
};
