import React, { useEffect, useState } from "react";
import {
    Box,
    Stack,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Paper,
    TablePagination,
    IconButton,
    Button,
    Switch,
} from "@mui/material";
import { PaginationSkeleton } from "../../assets/PaginationSkeleton";
import { RefressButton } from "../../assets/buttons/RefressButton";
import { FcAlphabeticalSortingAz } from "react-icons/fc";
import { AddButton } from "../../assets/buttons/AddButton";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getAllSetting } from "../../redux/actions/SettingAction";
import { useNavigate } from "react-router-dom";
import { TableBodyDataSkeleton } from "../../assets/TableBodyDataSkeleton";
import { allStyles } from "../../allStyles";
// import { AddUpdateSetting } from "./AddUpdateSetting";
import { RiEdit2Line } from "react-icons/ri";
import { NoDataSetMessage } from "../../components/NoDataSetMessage";
import { getAllPromocode, togglePromocodeApi } from "../../api/promocodeApi";
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function";
import { AddPromoCode } from "./AddPromoCode";

const columns = [
    {
        id: "serialNumber",
        label: "S#",
        minWidth: 20,
        align: "center",
        sortAction: true,
    },
    {
        id: "name",
        label: "Name",
        minWidth: 200,
        align: "center",
    },
    {
        id: "start_date",
        label: "Start Date",
        minWidth: 200,
        align: "center",
        sortAction: true,
    },
    {
        id: "end_date",
        label: "End Data",
        minWidth: 200,
        align: "center",
        sortAction: true,
    },
    {
        id: "is_enabled",
        label: "Is Enabled",
        minWidth: 200,
        align: "center",
        sortAction: true,
    },
    {
        id: "category_title",
        label: "Category",
        minWidth: 200,
        align: "center",
    },
    {
        id: "discount_percentage",
        label: "Discount %",
        minWidth: 200,
        align: "center",
        sortAction: true,
    },
    {
        id: "discount_days",
        label: "Discount Days",
        minWidth: 200,
        align: "center",
        sortAction: true,
    },
];

export const Promocode = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState("ASC");
    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedDataForEdit, setSelectedDataForEdit] = React.useState(null);

    const accessToken = useSelector(
        (state) => state.userTokensReducer.tokens.accessToken
    );

    const categoriesState = useSelector(state => state.Promocode)




    useEffect(() => {
        setLoading(categoriesState.isLoading)
        setRows(categoriesState.promoCode)
    
      }, [])
    
      useEffect(() => {
        setLoading(categoriesState.isLoading)
        setRows(categoriesState.promoCode)
      }, [categoriesState])
    













    const sortingColumns = (col) => {
        if (orderData === "ASC") {
            const sorted = [...rows].sort((a, b) =>
                a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
            );
            setRows([...sorted]);
            setOrderData("DSC");
        } else if (orderData === "DSC") {
            const sorted = [...rows].sort((a, b) =>
                a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
            );
            setRows([...sorted]);
            setOrderData("ASC");
        } else {
            const sorted = [...rows].sort((a, b) =>
                a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
            );
            setRows([...sorted]);
            setOrderData("ASC");
        }
    };

    // useEffect(() => {
    //     setLoading(tagsState.isLoading);
    //     setRows(tagsState.tags);
    // }, [tagsState]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Modal open handle through add button
    const addHandle = () => {
        setOpen(true);
    };

    // Modal Close Handle through modal cross button
    const closeClickHandle = () => {
        setOpen(false);
        getAllCodes()
        if (selectedDataForEdit) {
            setSelectedDataForEdit(null);
        }
    };

    const getAllCodes = () => {
        getAllPromocode(accessToken, dispatch, navigate).then((res) => {
            setRows(res.data.data);
            setLoading(false);
        })
    }
    const refreshHandle = () => {
        setLoading(true);
        getAllCodes()
    };

    const editHandle = (id, e) => {
        togglePromocodeApi(id, accessToken, dispatch, navigate).then(
            (res) => {
                if (res.data.message[0] == "Success") {
                    if (e.target.checked) {
                        displaySuccessToast(`PromoCode Disabled successfully!`)
                    }
                    else {
                        displaySuccessToast(`PromoCode Enabled successfully!`)
                    }
                    getAllCodes()
                }
                else {
                    displayErrorToast(res.data.message[0])
                }
            }
        )
    };
    // useEffect(() => {
    //     getAllCodes()
    // }, []);

    return (
        <Box>
            <Stack
                sx={{ px: 1, mb: 2 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack>
                    <Typography variant="h5">Promo Codes</Typography>
                </Stack>

                <Stack spacing={3} direction="row" alignItems="center">
                    {/* <RefressButton disabled={loading} onClickHandle={refreshHandle} /> */}
                    <Stack alignItems={"center"}>
                        <AddButton onClickHandle={addHandle} caption="PromoCode" />
                    </Stack>
                </Stack>
            </Stack>

            <Stack>
                <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={allStyles.tableHeight}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, i) => (
                                        <TableCell
                                            key={i}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.sortAction ? (
                                                <Button
                                                    sx={allStyles.actionsButtons}
                                                    onClick={() => sortingColumns(column.id)}
                                                >
                                                    {column.label}
                                                    <FcAlphabeticalSortingAz
                                                        size={20}
                                                        style={{ marginLeft: "15px" }}
                                                    />
                                                </Button>
                                            ) : (
                                                column.label
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            {loading ? (
                                <TableBodyDataSkeleton
                                    numberOfColumns={columns.length}
                                    imagePreview={false}
                                    editAction={true}
                                    deleteAction={false}
                                />
                            ) : rows.length !== 0 ? (
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, i) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={i}>
                                                    {columns.map((column, ind) => {
                                                        const value = row[column.id];
                                                        return column.id === "serialNumber" ? (
                                                            <TableCell key={ind} align={column.align}>
                                                                {page < 1
                                                                    ? i + 1
                                                                    : page * rowsPerPage + (i + 1)}
                                                            </TableCell>
                                                        ) : column.id === "is_enabled" ? (
                                                            <TableCell key={ind} align={column.align}>
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={2}
                                                                    justifyContent="center"
                                                                >
                                                                    <Switch
                                                                        checked={row.is_enabled}
                                                                        onChange={(e) => { editHandle(row.id, e) }}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                        ) :
                                                            column.id === "start_date" || column.id === "end_date" ? (
                                                                <TableCell key={ind} align={column.align}>
                                                                    {`${new Date(value).getDate()}-${new Date(value).getMonth() + 1}-${new Date(value).getFullYear()}`}
                                                                </TableCell>
                                                            ) :
                                                                (
                                                                    <TableCell key={ind} align={column.align}>
                                                                        {value}
                                                                    </TableCell>
                                                                );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            ) : (
                                <NoDataSetMessage lengthOfColumns={columns.length} />
                            )}
                        </Table>
                    </TableContainer>

                    {loading ? (
                        <PaginationSkeleton />
                    ) : (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Paper>
            </Stack>
            <AddPromoCode
                open={open}
                closeClickHandle={closeClickHandle}
                editState={selectedDataForEdit}
            />
            <ToastContainer />
        </Box>
    );
};

export default Promocode