import { Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {useNavigate  } from 'react-router-dom'

export const ErrorPage = () => {
  const navigate = useNavigate()
  
  const [user, setUser] = useState(false)
  const userStatus = useSelector(state => state.userStateReducer.user)

  useEffect(() => {
    setUser(userStatus)
  }, [])

  let onClickHandle = () => {
    navigate('/')
  }
  

  return (
    <Stack sx={{height:'90vh'}} justifyContent='center'>
     <Stack spacing={2}>
     <Typography variant='h4' align='center'>Page not found!</Typography>
      <Stack direction='row' justifyContent='center'><Button size='large' variant='outlined' sx={{textTransform:'none'}} onClick={onClickHandle} >{user?'Go to Dashboard':'Go to Login Page'}</Button></Stack>
     </Stack>
    </Stack>
  )
}
