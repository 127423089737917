import { apiHandle } from "./apiHandler";

// Get All Categories Api
export const getFaqApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get("/auth/faq");
};

export const createFaqApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post("/auth/faq", data);
};

export const updateFaqApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/auth/faq/${data.id}`,
    data
  );
};

export const deleteFaqApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).delete(
    `/auth/faq/${data.id}`
  );
};
