import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { allStyles } from '../allStyles'

export const SelectDropdownComponent = ({name,caption,sectionTitle,onChangeHandle,menuItems,defaultval}) => {

const [val, setVal] = useState('')
const [menuItemsList, setMenuItemsList] = useState([])

useEffect(() => {
    if(defaultval){
        setVal(defaultval)
    }
    else{
        setVal('')
    }
}, [defaultval])

useEffect(() => {
    if(menuItems){
        setMenuItemsList(menuItems)
    }
}, [menuItems])


const onChange = (e) => {
    setVal(e.target.value)
    onChangeHandle(e)
}

    
  return (
    <FormControl fullWidth sx={allStyles.modalFields}>
              <InputLabel>{caption}</InputLabel>
              <Select
                name={name}
                onChange={e => onChange(e)}
                label={caption}
                value={val}
                variant='outlined' 
                // style={{ width: '300px' }}
                >{menuItemsList.map((e,i)=><MenuItem key={i} value={sectionTitle === 'ProductsCategory' ? e['PKCategoryId'] : e.value }>{sectionTitle === 'ProductsCategory' ? e['NameOfCategory'] : e.caption}</MenuItem> )}
              </Select>
            </FormControl>
  )
}
